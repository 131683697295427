import Axios from "./../../../api/server";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const Docs = ({ gradeId, subjectId }) => {
  const [items, setItems] = useState([]);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  }, []);
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const res = await Axios.get(
          `/posts/docs/${subjectId}/${gradeId}`,
          config
        );
        setItems(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (gradeId && subjectId && config) {
      fetchItems();
    }
  }, [gradeId, subjectId, config]);

  return (
    <>
      <div className="docsWrapper">
        {items.map((item) => {
          return (
            <Link to={`/doc/${item.id}`} className="docItem" key={item.id}>
              {item.name}
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default Docs;
