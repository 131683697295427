import { useState, useEffect } from "react";
import Axios from "./../../../api/server";
import configuration from "./../../../helper/config";
import { Link } from "react-router-dom";
import "./../../../css/Wrapper.scss";
import { AddCircle } from "@mui/icons-material";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [config, setConfig] = useState(null);
  let sn = 1;

  useEffect(() => {
    setConfig(configuration);
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await Axios.get("/admin/users", config);
        setUsers(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchUsers();
  }, [config]);

  return (
    <>
      <div className="wrapper">
        <div className="heading">
          <Link to="/admin/users/create" className="icon-wrapper">
            <AddCircle className="add-icon" />
          </Link>
        </div>
        <table>
          <thead>
            <tr>
              <th>SN</th>
              <th>Full Name</th>
              <th>School</th>
              <th>Username</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => {
              return (
                <tr key={index}>
                  <td>{sn++}</td>
                  <td>
                    <p>{user.name}</p>
                  </td>
                  <td>
                    <p>{user.school && user.school.name}</p>
                  </td>
                  <td>
                    <p>{user.username}</p>
                  </td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td className="actions">
                    <Link to={`/admin/users/create/${user.id}`}>Edit</Link>
                    <p
                      className="delete-icon"
                      onClick={async () => {
                        const a = window.confirm("Are you sure ?");
                        if (a) {
                          await Axios.delete(`/admin/users/${user.id}`, config);
                          window.location.reload();
                        }
                      }}
                    >
                      Delete
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Users;
