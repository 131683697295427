import { useState, useEffect } from "react";
import Axios from "../../../api/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../../../css/Wrapper.scss";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";

import Button from "./../../Button/Button";
import Form from "./Form";

const Create = () => {
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [config, setConfig] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      email: "",
      schoolId: "",
      password: "",
      role: "",
    },
  });

  useEffect(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  }, []);

  if (err) {
    toast.error(err, {
      theme: "colored",
    });
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await Axios.get(`/admin/users/${id}`, config);
        formik.values.name = res.data.data.name;
        formik.values.username = res.data.data.username;
        formik.values.email = res.data.data.email;
        if (res.data.data.schoolId) {
          formik.values.schoolId = res.data.data.schoolId;
        }
        formik.values.role = res.data.data.role;
        // formik.values.password = res.data.data.password
        // formik.setValues(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (id && config) {
      fetchUser();
    }
  }, [config, id]);

  const handleFormSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = {
      name: formik.values.name,
      username: formik.values.username,
      email: formik.values.email,
      schoolId: !formik.values.schoolId ? null : formik.values.schoolId,
      password: formik.values.password,
      role: formik.values.role,
    };

    try {
      if (id) {
        await Axios.patch(`/admin/users/update/${id}`, data, config);
        toast.success("User updated successfully ", {
          theme: "colored",
        });
      } else {
        await Axios.post(`/admin/users`, data, config);
        formik.resetForm();
        toast.success("User added successfully ", {
          theme: "colored",
        });
      }
      setIsLoading(false);
      window.moveTo(0, 0);
    } catch (err) {
      console.log(err);
      console.log(err.response);
      setErr(err.response.data.err);
      setErr(null);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleFormSubmit}>
        <Form formik={formik} id={id} />
        <div className="btn">
          <Button isEdit={id ? true : false} isLoading={isLoading} />
        </div>
      </form>
    </>
  );
};

export default Create;
