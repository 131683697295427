import { useState, useEffect } from "react";
import Axios from "../../../api/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../../../css/Wrapper.scss";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";

import Button from "./../../Button/Button";
import Form from "./Form";

const Create = () => {
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [config, setConfig] = useState(null);
  const [user, setUser] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      classId: "",
      subjectId: "",
      file: null,
    },
  });

  useEffect(() => {
    const fetchUser = async () => {
      const res = await Axios.get(`/user`, config);
      setUser(res.data.data);
    };
    if (config) {
      fetchUser();
    }
  }, [config]);

  useEffect(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  }, []);

  if (err) {
    toast.error(err, {
      theme: "colored",
    });
  }

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const res = await Axios.get(`/admin/docs/${id}`, config);
        formik.setValues(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (id && config) {
      fetchDocs();
    }
  }, [config, id]);

  const handleFormSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", formik.values.name);
    formData.append("subjectId", formik.values.subjectId);
    formData.append("classId", formik.values.classId);
    formData.append("file", formik.values.file);
    formData.append(
      "schoolId",
      user.role === "schoolAdmin" ? user.schoolId : null
    );

    try {
      if (id) {
        if (user.role === "schoolAdmin") {
          await Axios.patch(`/school-admin/docs/${id}`, formData, config);
        } else {
          await Axios.patch(`/admin/docs/${id}`, formData, config);
        }
        toast.success("Document updated successfully ", {
          theme: "colored",
        });
      } else {
        if (user.role === "schoolAdmin") {
          await Axios.post(`/school-admin/docs`, formData, config);
        } else {
          await Axios.post(`/admin/docs`, formData, config);
        }
        formik.resetForm();
        toast.success("Document added successfully ", {
          theme: "colored",
        });
      }
      setIsLoading(false);
      window.moveTo(0, 0);
    } catch (err) {
      console.log(err);
      console.log(err.response);
      setErr(err.response.data.err);
      setErr(null);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleFormSubmit}>
        <Form formik={formik} id={id} />
        <div className="btn">
          <Button isEdit={id ? true : false} isLoading={isLoading} />
        </div>
      </form>
    </>
  );
};

export default Create;
