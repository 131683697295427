import jwt_decode from "jwt-decode";

const checkJwtToken = () => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    try {
      const decoded = jwt_decode(token);
      if (!decoded) {
        logout();
      }
      const current_time = new Date().getTime() / 1000;
      if (current_time > decoded.exp) {
        logout();
      }
      return true;
    } catch (err) {
      logout();
    }
  } else {
    logout();
  }
};

const isAdmin = () => {
  if (localStorage.getItem("role") === "admin") {
    return true;
  } else {
    return false;
  }
};

const isSchoolAdmin = () => {
  if (localStorage.getItem("role") === "schoolAdmin" || "admin") {
    return true;
  } else {
    return false;
  }
};

const isStudent = () => {
  if (localStorage.getItem("role") === "student" || "admin" || "schoolAdmin") {
    return true;
  } else {
    return false;
  }
};

const logout = () => {
  localStorage.clear();
  // window.location.href = "/login";
  return false;
};

export { checkJwtToken, logout, isAdmin, isStudent, isSchoolAdmin };
