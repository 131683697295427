import { useState, useEffect } from "react";
import Axios from "../../../api/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../../../css/Wrapper.scss";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";

import Button from "./../../Button/Button";
import Form from "./Form";
import { useRadioGroup } from "@mui/material";

const Create = () => {
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [config, setConfig] = useState(null);
  const [user, setUser] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      link: "",
      classId: "",
      subjectId: "",
    },
  });

  useEffect(() => {
    const fetchUser = async () => {
      const res = await Axios.get(`/user`, config);
      setUser(res.data.data);
    };
    if (config) {
      fetchUser();
    }
  }, [config]);

  useEffect(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  }, []);

  if (err) {
    toast.error(err, {
      theme: "colored",
    });
  }

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const res = await Axios.get(`/admin/videos/${id}`, config);
        formik.setFieldValue("name", res.data.data.name);
        formik.setFieldValue("link", res.data.data.link);
        formik.setFieldValue("subjectId", res.data.data.subject.id);
        formik.setFieldValue("classId", res.data.data.class.id);
      } catch (err) {
        console.log(err);
      }
    };
    if (id && config) {
      fetchSubjects();
    }
  }, [config, id]);

  const handleFormSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = {
      name: formik.values.name,
      link: formik.values.link,
      classId: formik.values.classId,
      subjectId: formik.values.subjectId,
      schoolId: user.role === "schoolAdmin" ? user.schoolId : null,
    };
    try {
      if (id) {
        if (user.role === "schoolAdmin") {
          await Axios.patch(`/school-admin/videos/${id}`, data, config);
        } else {
          await Axios.patch(`/admin/videos/${id}`, data, config);
        }
        toast.success("Video updated successfully ", {
          theme: "colored",
        });
      } else {
        if (user.role === "schoolAdmin") {
          await Axios.post(`/school-admin/videos`, data, config);
        } else {
          await Axios.post(`/admin/videos`, data, config);
        }
        formik.resetForm();
        toast.success("Video added successfully ", {
          theme: "colored",
        });
      }
      setIsLoading(false);
      window.moveTo(0, 0);
    } catch (err) {
      console.log(err);
      console.log(err.response);
      setErr(err.response.data.err);
      setErr(null);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleFormSubmit}>
        <Form formik={formik} id={id} />
        <div className="btn">
          <Button isEdit={id ? true : false} isLoading={isLoading} />
        </div>
      </form>
    </>
  );
};

export default Create;
