import { Link } from "react-router-dom";
import { logout } from "./../../../helper/helper";
import "./index.scss";

const Navbar = ({ user }) => {
  return (
    <>
      <nav className="homeNav">
        {user.school && user.role !== "admin" ? (
          <div className="image">
            <Link to="/">
              <img src={user.school.image} alt="" />
            </Link>
            <h4>{user.school.name}</h4>
          </div>
        ) : (
          <div className="logo">
            <Link to="/" className="link">
              <h3>Admin</h3>
            </Link>
          </div>
        )}

        <div className="details">
          <p>{user.name}</p>
          {user.role === ("admin" || "schoolAdmin") && (
            <Link to="/admin">
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Admin
              </a>
            </Link>
          )}
          <p
            onClick={() => {
              logout();
              window.location.reload();
            }}
          >
            Logout
          </p>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
