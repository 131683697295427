import {
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from "../../../api/server";

const Form = ({ formik, id }) => {
  const [schools, setSchools] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  }, []);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const res = await Axios.get("/admin/schools", config);
        setSchools(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (config) {
      fetchSchools();
    }
  }, [config]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const res = await Axios.get("/admin/schools", config);
        setSubjects(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (config) {
      fetchSubjects();
    }
  }, [config]);

  return (
    <div className="wrapper">
      <div className="form-wrapper">
        <div className="heading">
          <div className="title">
            <h3>{id ? "Update User" : "Add A User"}</h3>
          </div>
          <Link to="/admin/users" className="link">
            <CancelOutlined className="icon" />
          </Link>
        </div>
        <div className="form">
          <div className="doubleInputWrapper">
            <TextField
              id="outlined-basic-1"
              label="Full Name"
              variant="outlined"
              className="input"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            <TextField
              id="outlined-basic-1"
              label="Username"
              variant="outlined"
              className="input"
              name="username"
              onChange={formik.handleChange}
              value={formik.values.username}
            />
          </div>
          <div className="doubleInputWrapper">
            <TextField
              id="outlined-basic-1"
              label="Email Address"
              variant="outlined"
              className="input"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />

            <FormControl variant="outlined" className="select">
              <InputLabel id="demo-simple-select-outlined-label">
                School
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                label="School"
                name="schoolId"
                value={formik.values.schoolId}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              >
                {schools?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="doubleInputWrapper">
            <FormControl variant="outlined" className="select">
              <InputLabel id="demo-simple-select-outlined-label">
                Role
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                label="Role"
                name="role"
                value={formik.values.role}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              >
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"schoolAdmin"}>School Admin</MenuItem>
                <MenuItem value={"student"}>Student</MenuItem>
              </Select>
            </FormControl>
            {!id && (
              <TextField
                id="outlined-basic-1"
                label="Password"
                variant="outlined"
                className="input"
                type="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
