import {
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from "../../../api/server";

const Form = ({ formik, id }) => {
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  }, []);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const res = await Axios.get("/admin/classes", config);
        setClasses(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (config) {
      fetchClasses();
    }
  }, [config]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const res = await Axios.get("/admin/subjects", config);
        setSubjects(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (config) {
      fetchSubjects();
    }
  }, [config]);

  return (
    <div className="wrapper">
      <div className="form-wrapper">
        <div className="heading">
          <div className="title">
            <h3>{id ? "Update Video" : "Add A Video"}</h3>
          </div>
          <Link to="/admin/videos" className="link">
            <CancelOutlined className="icon" />
          </Link>
        </div>
        <div className="form">
          <div className="doubleInputWrapper">
            <TextField
              id="outlined-basic-1"
              label="Video Name"
              variant="outlined"
              className="input"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            <TextField
              id="outlined-basic-1"
              label="Video Link"
              variant="outlined"
              className="input"
              name="link"
              onChange={formik.handleChange}
              value={formik.values.link}
            />
          </div>
          <div className="doubleInputWrapper">
            <FormControl variant="outlined" className="select">
              <InputLabel id="demo-simple-select-outlined-label">
                Subject
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                label="Subject"
                name="subjectId"
                value={formik.values.subjectId}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              >
                {subjects?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className="select">
              <InputLabel id="demo-simple-select-outlined-label">
                Class
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                label="Class"
                name="classId"
                value={formik.values.classId}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              >
                {classes?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
