import { Link } from "react-router-dom";
import { BookSharp } from "@mui/icons-material";

const Home = () => {
  return (
    <>
      <div className="card-wrapper">
        <Link to="/" className="card first">
          <div className="text-wrapper">
            <h1>Home Page</h1>
            <p>Library</p>
          </div>
          <BookSharp className="icon" />
        </Link>
      </div>
    </>
  );
};

export default Home;
