import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from "./../../../api/server";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const Single = () => {
  const [doc, setDoc] = useState(null);
  const [config, setConfig] = useState(null);
  const { docId } = useParams();
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  }, []);

  useEffect(() => {
    const fetchDocById = async () => {
      try {
        const res = await Axios.get(`/posts/docs/single/${docId}`, config);
        console.log(res.data.data);
        setDoc(res.data.data);
      } catch (err) {
        console.log(err);
        if (err && err.response) {
          if (err.response.status === 404) {
            navigate("/", { replace: true });
          }
        }
      }
    };

    if (docId && config) {
      fetchDocById();
    }
  }, [docId, config]);

  return doc ? (
    <>
      <div
        style={{
          height: "100vh",
        }}
      >
        <iframe
          title={doc.name}
          src={doc.file}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </>
  ) : (
    <p>Loading...</p>
  );
};

export default Single;
