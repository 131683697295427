import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "./../css/Home.scss";

import AdminRoute from "./PrivateRoute/AdminRoute";
import StudentRoute from "./PrivateRoute/StudentRoute";
import SchoolRoute from "./PrivateRoute/SchoolRoute";
import Login from "./Admin/Login/Login";
import Navbar from "./Navbar/Navbar";

// admin routes
import Home from "./Admin/Home/Home";
import Users from "./Admin/Users/Users";
import CreateUser from "./Admin/Users/Create";
import Inbox from "./Admin/Inbox/Inbox";
import Settings from "./Admin/Settings/Settings";

import Schools from "./Admin/School/School";
import CreateSchool from "./Admin/School/Create";

import Classes from "./Admin/Class/Class";
import CreateClass from "./Admin/Class/Create";

import Subject from "./Admin/Subject/Subject";
import CreateSubject from "./Admin/Subject/Create";

import Videos from "./Admin/Videos/Videos";
import CreateVideo from "./Admin/Videos/Create";

import Docs from "./Admin/Docs/Docs";
import CreateDocs from "./Admin/Docs/Create";

// student routes
import StudentHome from "./Student/Home/Home";
import SingleDoc from "./Student/Docs/Single";

const RouterComponent = () => {
  const AdminContainer = () => {
    return (
      <div className="home-wrapper">
        <Navbar />
        <div className="home">
          <Outlet />
        </div>
      </div>
    );
  };
  const StudentContainer = () => {
    return <Outlet />;
  };
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<AdminRoute />}>
          <Route element={<AdminContainer />}>
            <Route path="/admin/inbox" element={<Inbox />} />

            <Route path="/admin/schools" element={<Schools />} />
            <Route path="/admin/schools/create" element={<CreateSchool />} />
            <Route
              path="/admin/schools/create/:id"
              element={<CreateSchool />}
            />

            <Route path="/admin/classes" element={<Classes />} />
            <Route path="/admin/classes/create" element={<CreateClass />} />
            <Route path="/admin/classes/create/:id" element={<CreateClass />} />

            <Route path="/admin/subjects" element={<Subject />} />
            <Route path="/admin/subjects/create" element={<CreateSubject />} />
            <Route
              path="/admin/subjects/create/:id"
              element={<CreateSubject />}
            />

            <Route path="/admin/users" element={<Users />} />
            <Route path="/admin/users/create" element={<CreateUser />} />
            <Route path="/admin/users/create/:id" element={<CreateUser />} />
            <Route path="/admin/settings" element={<Settings />} />
          </Route>
        </Route>

        <Route element={<SchoolRoute />}>
          <Route element={<AdminContainer />}>
            <Route path="/admin/" element={<Home />} />

            <Route path="/admin/videos" element={<Videos />} />
            <Route path="/admin/videos/create" element={<CreateVideo />} />
            <Route path="/admin/videos/create/:id" element={<CreateVideo />} />

            <Route path="/admin/docs" element={<Docs />} />
            <Route path="/admin/docs/create" element={<CreateDocs />} />
            <Route path="/admin/docs/create/:id" element={<CreateDocs />} />

            <Route path="/admin/settings" element={<Settings />} />
          </Route>
        </Route>

        {/* student routes  */}
        <Route element={<StudentRoute />}>
          <Route element={<StudentContainer />}>
            <Route path="/" element={<StudentHome />} />
            <Route path="/doc/:docId" element={<SingleDoc />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default RouterComponent;
