import Axios from "./../../../api/server";
import { useState, useEffect } from "react";
import "./index.scss";

const Videos = ({ gradeId, subjectId }) => {
  const [items, setItems] = useState([]);
  const [config, setConfig] = useState(null);

  const getId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  useEffect(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const res = await Axios.get(
          `/posts/videos/${subjectId}/${gradeId}`,
          config
        );
        setItems(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (gradeId && subjectId && config) {
      fetchItems();
    }
  }, [gradeId, subjectId, config]);

  return (
    <>
      <div className="videosWrapper">
        {items.map((item) => {
          const video = getId(item.link);
          return (
            <div key={item.id} className="item">
              <iframe
                className="video"
                src={`https://www.youtube.com/embed/${video}`}
                title={item.name}
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              ></iframe>

              <p>{item.name}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Videos;
