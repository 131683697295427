import Navbar from "./../Navbar/Navbar";
import { useEffect, useState } from "react";
import Axios from "./../../../api/server";
import { Link } from "react-router-dom";
import {
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import "./index.scss";

import Docs from "../Docs/Docs";
import Videos from "../Videos/Videos";

const Home = () => {
  const [user, setUser] = useState(null);
  const [config, setConfig] = useState(null);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [gradeId, setGradeId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);
  const [displayItem, setDisplayItem] = useState("docs");

  useEffect(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  }, []);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const res = await Axios.get("/posts/subjects", config);
        setSubjects(res.data.data);
        setSubjectId(res.data.data[0].id);
      } catch (err) {
        console.log(err);
      }
    };
    const fetchGrades = async () => {
      try {
        const res = await Axios.get("/posts/classes", config);
        setGrades(res.data.data);
        setGradeId(res.data.data[0].id);
      } catch (err) {
        console.log(err);
      }
    };
    if (config) {
      fetchSubjects();
      fetchGrades();
    }
  }, [config]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await Axios.get(`/user`, config);
        setUser(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (config) {
      fetchUser();
    }
  }, [config]);

  return user ? (
    <>
      <Navbar user={user} />
      <div className="studentHome">
        <h1>Welcome {user.name} !</h1>
        <div className="wrapper">
          <div className="library">
            <div className="gradeToggler">
              <FormControl variant="outlined" className="select">
                <InputLabel id="demo-simple-select-outlined-label">
                  Subject
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  label="Subject"
                  name="subjectId"
                  value={subjectId}
                  onChange={(e) => {
                    setSubjectId(e.target.value);
                  }}
                >
                  {subjects?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className="select">
                <InputLabel id="demo-simple-select-outlined-label">
                  Grade
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  label="Grade"
                  name="gradeId"
                  value={gradeId}
                  onChange={(e) => {
                    setGradeId(e.target.value);
                  }}
                >
                  {grades?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="contentWrapper">
              <div className="toggle">
                <p
                  className={displayItem === "docs" ? "selected" : ""}
                  onClick={() => {
                    setDisplayItem("docs");
                  }}
                >
                  Documents
                </p>
                <p
                  className={displayItem === "videos" ? "selected" : ""}
                  onClick={() => {
                    setDisplayItem("videos");
                  }}
                >
                  Videos
                </p>
              </div>

              <div className="content">
                {displayItem === "docs" ? (
                  <Docs gradeId={gradeId} subjectId={subjectId} />
                ) : (
                  <Videos gradeId={gradeId} subjectId={subjectId} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <p>Loading</p>
  );
};

export default Home;
